/* eslint-disable */
// prettier-ignore
export default {
  language: [
    {
      locale: 'vi',
      img: require('@/assets/images/flags/vi.png'),
      name: 'Vietnamese',
    },
    {
      locale: 'en',
      img: require('@/assets/images/flags/en.png'),
      name: 'English',
    },
    {
      locale: 'km',
      img: require('@/assets/images/flags/km.jpg'),
      name: 'Cambodia',
    },
    // {
    //   locale: 'fr',
    //   img: require('@/assets/images/flags/fr.png'),
    //   name: 'French',
    // },
    // {
    //   locale: 'de',
    //   img: require('@/assets/images/flags/de.png'),
    //   name: 'German',
    // },
    // {
    //   locale: 'pt',
    //   img: require('@/assets/images/flags/pt.png'),
    //   name: 'Portuguese',
    // },
  ]
}
/* eslint-enable */
