/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import Request from '@/plugins/service/index'
import languageJson from '@/libs/lang/lang'

export default {
  methods: {
    showError(obj, sep) {
      let str = ''
      Object.keys(obj).map((i, index) => {
        if (index === 0) {
          str += obj[i]
        } else {
          str += `${sep}${obj[i]}`
        }
      })
      return str
    },
    getCurrentDate(dayTime = false) {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      if (dayTime) {
        const hh = String(today.getHours()).padStart(2, '0')
        const ii = String(today.getMinutes()).padStart(2, '0')
        const s = String(today.getSeconds()).padStart(2, '0')
        today = `${yyyy}/${mm}/${dd} ${hh}:${ii}:${s}`
      } else {
        today = `${yyyy}/${mm}/${dd}`
      }
      return today
    },
    obsKeysToString(obj, sep) {
      let str = ''
      for (let i = Object.keys(obj).length - 1; i >= 0; i--) {
        if (i === Object.keys(obj).length - 1) {
          str += `${Object.keys(obj)[i]}=${obj[Object.keys(obj)[i]]}`
        } else {
          str += `${sep}${Object.keys(obj)[i]}=${obj[Object.keys(obj)[i]]}`
        }
      }
      return str
    },
    sortByKey(array, key) {
      return array.sort((a, b) => {
        const x = a[key]
        const y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      })
    },
    arr_diff(a1, a2) {
      const a = []
      let diff = []
      for (let i = 0; i < a1.length; i++) {
        a[a1[i]] = true
      }
      for (let i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
          delete a[a2[i]]
        } else {
          a[a2[i]] = true
        }
      }
      diff = a
      return diff
    },
    comparer(otherArray, field) {
      return (current => otherArray.filter(other => other[field] === current[field]).length === 0)
    },
    formatDate(date) {
      const day = date.split('-')[2]
      const month = date.split('-')[1]
      const year = date.split('-')[0]
      return `${day}/${month}/${year}`
    },
    formatDateReverse(date) {
      const day = date.split('/')[0]
      const month = date.split('/')[1]
      const year = date.split('/')[2]
      return `${year}-${month}-${day}`
    },
    convertToSlug(title) {
      let slug
      // Đổi chữ hoa thành chữ thường
      slug = title.toLowerCase()
      // Đổi ký tự có dấu thành không dấu
      slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
      slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
      slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
      slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
      slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
      slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
      slug = slug.replace(/đ/gi, 'd')
      // Xóa các ký tự đặt biệt
      slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '')
      // Đổi khoảng trắng thành ký tự gạch ngang
      slug = slug.replace(/ /gi, '-')
      // Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
      // Phòng trường hợp người nhập vào quá nhiều ký tự trắng
      slug = slug.replace(/\-\-\-\-\-/gi, '-')
      slug = slug.replace(/\-\-\-\-/gi, '-')
      slug = slug.replace(/\-\-\-/gi, '-')
      slug = slug.replace(/\-\-/gi, '-')
      // Xóa các ký tự gạch ngang ở đầu và cuối
      slug = `@${slug}@`
      slug = slug.replace(/\@\-|\-\@|\@/gi, '')

      return slug
    },
    getDataUrl(img) {
      // Create canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // Set width and height
      canvas.width = img.width
      canvas.height = img.height
      // Draw the image
      ctx.drawImage(img, 0, 0)
      return canvas.toDataURL('image/jpeg')
    },
    generateCode(perfix, min, max) {
      const rand1 = Math.floor(Math.random() * (max - min + 1)) + min
      const rand2 = Math.floor(Math.random() * (max - min + 1)) + min
      const rand3 = Math.floor(Math.random() * (max - min + 1)) + min
      const rand4 = Math.floor(Math.random() * (max - min + 1)) + min
      const rand5 = Math.floor(Math.random() * (max - min + 1)) + min
      return `${perfix.toUpperCase()}${rand1}${rand2}${rand3}${rand4}${rand5}`
    },
    async loadLanguage() {
      const languageOptions = []
      const res = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/languages?template_id=${
          JSON.parse(localStorage.getItem('templateID')).id
        }`,
      )
      if (res.status === 200) {
        if (res.data.status) {
          res.data.data.items.map(val => {
            const langFil = languageJson.language.filter(
              lang => lang.locale === val.locale,
            )
            languageOptions.push({
              locale: langFil[0].locale,
              name: langFil[0].name,
              img: langFil[0].img,
            })
            return val
          })
        }
      }
      return languageOptions
    },
  },
}
